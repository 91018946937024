.pages-header {
  background-color: #F8F8F8;
  margin-bottom: 50px;
  font-family: avalon-bold;
}

.padding-50 {
  padding: 50px 0;
}

.center {
  justify-content: center;
}

.left-step, .right-step {
  display: flex;
  align-items: center;
  width: 25%;
}

.center-step {
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
}

.stepper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.lines {
  width: 100%;
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: row;
}

.half {
  width: 50%;
}

.full {
  width: 100%;
}

.complete-line {
  border-bottom: black 2px solid;
}

.incomplete-line {
  border-bottom:  #b1afaf 2px solid;
}

.stepper-mark {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.background-black {
  background-color: #000;
}

.background-grey {
  background-color: #b1afaf;
}

.step-label {
  color: black;
  text-transform: uppercase;
  text-align: center;
  font-size: 1em;
  font-family: 'avalon-bold';
}

.oval-step {
  width: 24px;
  height: 24px;
  line-height: 20px;
  border-radius: 24px;
}

.stepper-container {
  transform: translateY(12px);
}

.color-black {
  color: #000;
}

.color-grey {
  color: #b1afaf;
}
.p-t-75{
    padding-top: 75px;
}

.p-b-82{
    padding-bottom: 82px;
}
.p-b-46{
    padding-bottom: 46px;
}


.margin-114{
    margin-left: 114px;
    margin-right: 114px;
}

.title-resellfridges{
    font-family: avalon-bold;
    font-size: 18px;
    font-weight: bold;
}


.condition{
    font-family: avalon-regular;
    font-size: 16px;
    
}

.margin-0{
    margin: 0 !important;
    padding: 0 !important;
}

.p-b-48{
    padding-bottom: 48px;
}



.p-b-160{
    padding-bottom: 100px;
}

.p-b-20{
    padding-bottom: 20px;
}

.p-b-14{
    padding-bottom: 14px;
}

.p-b-13{
    padding-bottom: 13px;
}

.p-b-32{
    padding-bottom: 14px;
}


.p-b-28{
    padding-bottom: 28px;
}

.p-b-110{
    padding-bottom: 110px;
}

.what-accept{
    font-family: avalon-bold;
    font-size: 14px;
    letter-spacing: 1px;
}

.margin-l-3{
    margin-left: 10px
}

.photosModal{
    box-sizing: border-box;
    height: 150px;
    width: 100%;
    border: 1px solid #A0A0A0;
  }

  .image-object-fit{
    object-fit: contain;
}

@media (max-width: 768px) {
    .margin-114{
      margin:0px;
    }



  }
  
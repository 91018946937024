.reserlfridges-buyback{
    color: #212121;
  font-family: avalon-bold;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
}

.pt-50{
    padding-top: 50px;
}

.pt-32{
    padding-top: 32px;
}


.pt-18{
    padding-top: 10px;
}

.we-are-excited-that{
    color: #212121;
  font-family: avalon-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  word-wrap: break-word;

}

.what-is-resellfridge{
color: #212121;
  font-family: avalon-bold;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
}


.faq-heading {
  color: #212121;
  font-family: avalon-bold;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  
 
}

.faq-text {
  font-family: Open Sans;   
  font-weight: 400;
  color: #919191;
 
  color: #212121;
  font-family: avalon-regular;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom:30px;
}


.faq label {
  display: block;
  position: relative;
  cursor: pointer;
  background-color: white;
  overflow: hidden;
}

.faq input[type="checkbox"] {
  display: none;
}

.faq .faq-arrow {
  width: 15px;
  height: 15px;
  transition: -webkit-transform 0.8s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-right: 2px solid rgba(0, 0, 0, 0.33);
  border-bottom: 2px solid rgba(0, 0, 0, 0.33);
  float: right;
  position: relative;
  top: -47px;
  right: 27px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

 .faq input[type="checkbox"]:checked + label > .faq-arrow {
  transition: -webkit-transform 0.2s;
  transition: transform 0.1s;
  transition: transform 0.1, -webkit-transform 0.1s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: rotate(135deg);
          transform: rotate(226deg);
}
 .faq input[type="checkbox"]:checked + label {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 30vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}

 .faq input[type='checkbox']:not(:checked) + label {
  display: block;
  transition: height 0.2s;
  height: 50px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}



hr.black{
  margin-top: 9px;
  margin-bottom: 0px;
  border-top: 1px solid#e7e7e7;
}


div.maxWidth829{
  max-width: 829px;
}

div.maxWidth876{
  max-width: 876px;
}


.faq input[type='checkbox']:checked + label.small {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 40vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}


.faq input[type='checkbox']:checked + label.medium {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 45vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}

.faq input[type='checkbox']:checked + label.large {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 55vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}


.faq input[type='checkbox']:checked + label.extra-large {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 55vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}



.faq input[type='checkbox']:checked + label.extra-extra-large {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 60vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}


.faq input[type='checkbox']:checked + label.extra-extra-extra-large {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 65vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}







@media only screen and (max-width: 500px) {

  .reserlfridges-buyback{

    font-size: 21px;
  
  }

  .faq-arrow{
    display: none;
  }

  .faq input[type="checkbox"]:checked + label {
    display: block;
    background: rgba(255,255,255,255) !important;
    height: 40vh;
    
    transition: height 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
  }


  .faq input[type='checkbox']:checked + label.medium {
    display: block;
    background: rgba(255,255,255,255) !important;
    height: 55vh;
    
    transition: height 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
  }

  .faq input[type='checkbox']:checked + label.large {
    display: block;
    background: rgba(255,255,255,255) !important;
    height: 60vh;
    
    transition: height 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
  }





  

.faq input[type='checkbox']:checked + label.extra-extra-extra-large {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 70vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}




}



@media only screen and (max-width: 370px) {


  .faq input[type="checkbox"]:checked + label {
    display: block;
    background: rgba(255,255,255,255) !important;
    height: 50vh;
    
    transition: height 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
  }


  .faq input[type='checkbox']:checked + label.medium {
    display: block;
    background: rgba(255,255,255,255) !important;
    height: 75vh;
    
    transition: height 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
  }

  .faq input[type='checkbox']:checked + label.large {
    display: block;
    background: rgba(255,255,255,255) !important;
    height: 80vh;
    
    transition: height 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
  }





  

.faq input[type='checkbox']:checked + label.extra-extra-extra-large {
  display: block;
  background: rgba(255,255,255,255) !important;
  height: 100vh;
  
  transition: height 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265);
}





 
}
.max-width-1050 {
  max-width: 1050px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-x-15 {
  padding: 0 15px;
}

/* fonts are in app.css file */
.banner {
    width: 100%;
    
    height: 70vh;

    background-image: url("/Resellfridges-hero.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.banner-text{
text-align: center;
color: rgb(5, 5, 5);
max-width: 650px;
}

.Resellfridges-logo {
    width: 265px;
    height: 40px;
    object-fit: contain;
  }


.padding-t-38{
    padding-top:38px
}

.padding-t-24{
    padding-top:24px
}

.pt-22{
  padding-top: 22px;
}

.pt-6{
  padding-top: 6px;
}

div.padding{
    padding-top: 147px;
}

div.padding-up{
    padding-top: 48px;
    padding-bottom: 54px;
}

  .subtitle-bottom{
    padding-bottom: 32px;
  }

  .padding-48-48{
      padding-bottom: 48px;
      padding-top: 48px;
  }

  .p-b-60{
    padding-bottom: 60px;
}

.pb-25{
  padding-bottom: 25px;
}

.pb-37{
  padding-bottom: 37px;
}



.pt-37{
  padding-top: 37px;
}




  .oval {
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #212121;
    font-weight: 1000;
    padding-bottom: 10;
  }

  .lh-40{
    line-height: 40px;
  }

  .container-margin{
    margin-left: 141px;
    margin-right: 141px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pt-64{
    padding-top: 64px;
  }
  .pb-64{
    padding-bottom: 64px;
  }


    .p-r-65{
      padding-right: 65px;
    }

    .p-l-65{
      padding-left: 65px;
    }

    .p-t-0{
      padding-top: 0px !important;
    }

    .pbt-20{
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .p-b-0{
      padding-bottom: 0px !important;
    }

    .row-padding {
      position: relative;
      width: 100%;
      padding-right: 5px !important;
      padding-left: 5px !important;
      padding-bottom: 10px;

    }


 .maxWidthGroup{
    max-width: 275px;
 }

 .ls-0{
  letter-spacing: 0;
 }

  .why-use-sellfridges-details{
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    color: #212121;
  } 


  .well-credit-you-as {
    color: #212121;
    letter-spacing: 0;
    line-height: 24px;
  }


  .padding-around{
    padding: 24px 24px 8px 24px;
    margin: 0;
  }

  .padding-b-23{
    padding-bottom: 23px;
  }

  .pb-15{
    padding-bottom: 15px;
  }


  div.col-md-4{
      max-width: 371px;
      
  }

  div.background-primary{
      margin-left: 5px;
  }


 
  .Rectangle {
    width: 100%;
    height: 100%;
    background-size: cover;
    margin-left: 0px;
    background-image: url("/Resellfridges-banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }


  div.row.container-margin.toRemove{
    display: -ms-flexbox;
    display: flex;

  }


  

  div.row.container-margin.toShowinMobile{
    display: none;
  }


  @media only screen and (max-width: 414px) {

    .why-use-sellfridges-details{
      font-size: 18px;
      margin-bottom: 5px !important;

    } 
  

    .padding-mobile-header{
      padding-top: 40px !important;
    }

    
    h2.whyUseResell{
      font-size: 21px;
    }

    h2.does-title.text-align-center.accepted{
      font-size: 21px;
    }

    .col-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    div.col-6.row-padding{
      line-height: 30px;
    }


    h2.does-title.p-t-46.text-align-center{
      padding-top: 20px !important;
      padding-bottom: 10px;
      font-size: 21px;
    }


    div.padding-responsive-works{
      padding-top: 36px;;
    }

    div.banner{
      background-image: url("/Resellfridges-hero-mobile.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 450px;

    }

    .Rectangle {
      width: 100%;
      height: 450px;
      background-size: cover;
      background-image: url("/Resellfridges-supporting-mobile.jpg");
      background-repeat: no-repeat;
      background-position: center;
    }

    div.margin-group{
      text-align: center;
  }


    div.col-md-6.pt-37.pb-37.padding0{
      padding-top: 10px !important;
    }

   div.row.container-margin.toRemove{
     display: none;

   }

   div.row.container-margin.toShowinMobile{
     padding-top: 20px;
    display: -ms-flexbox;
    display: flex;
  }

    .container-margin{
      margin-left: 0px;
      margin-right: 0px;
      padding-top: 0px;
      padding-bottom: 15px;
    }

    div.responsive-accepted-class{
      padding-top: 20px !important;
    }

    p.details{
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .responsive-margin{
      margin-left: 10px;
      margin-right: 10px;
    }




    
    h2.goTABag.text-align-center{
      margin-left: 40px;
      margin-right: 40px;
      font-size: 21px;
    }

    h2.does-title.text-align-center.accepted{
      margin-left: 30px;
      margin-right: 30px;
    }

   .padding-mobile{
     padding-top: 15px !important;
   }

  }
  




  div.row.background-primary{
    height: 594px;
  }


  div.margin-group{
      margin-left:49px;
      margin-right: 49px
  }

  .background-off-white{
    background-color: #f8f8f8;
    height: 100%;
}


.goTABag{
  font-family: avalon-bold;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
}

div.row .col-md-3 p.details{
 
  letter-spacing: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}


.padding-30-36{
    padding-top: 15px;
    padding-bottom:36px;
}


.padding-30-28{
  padding-top: 30px;
  padding-bottom:28px;
}



.max-width-969{
    max-width: 969px;
}


hr.black{
        margin-top: 62px;
        border-top: 1px solid #212121;
}


.padding0{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.schedule-pickup-titles {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.25em;
}

.border-1-black {
  border: 1px solid #f1f1f1;
  padding: 10px;
  margin: 0 -15px 0 auto;
  cursor: pointer;
}

.packaging-type {
  margin-bottom: 2rem;
}

.calendar-box {
  border: 1px solid #f1f1f1;
  padding: 10px;
  text-align: center;
  width: calc(100% / 7);
}

.calendar-box--selectable {
  cursor: pointer;
}

.date-picker-disabled {
  background-color: #f1f1f1;
}

.dark-selection {
  background-color: #545454;
  border: 1px solid #545454;
  color: #fff;
}

.set-time {
  border: 1px solid #f1f1f1;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  width: 20%;
  margin-right: 8px;

  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-around;
  align-items: center;
}

.selected {
  border: 1px solid #000;
}

.check-black-container {
  position: relative;
}

.check-black {
  border-left: 1px solid #545454;
  border-bottom: 1px solid #545454;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 14px;
  height: 6px;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
}

.comment-text {
  font-size: 12px;
}

.summary-box {
  background-color: #f1f1f1;
  padding: 20px;
}

.summary-image {
  width: 100%;
  margin-right: -15px;
}
.summary-info {
  margin-bottom: 15px;
}
.summary-info-title,
.summary-info-desc {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .set-time {
    flex-direction: column;
  }
}

.padding-t-8{
    padding-top: 8px;
  }

  .photos{
    box-sizing: border-box;
    height: 159.9px;
    width: 164px;
    border: 1px dashed #A0A0A0;

  }


  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }

  input.input-photos {
    opacity: 0;
    position: relative;
    top: -30px;
    left: 0px;
    height: 100%;
    width: 100%;
  }

  div.text-align-center p.error{
    color: red;
  }


.circle{
    border: 1px solid #e7e7e7;

    width: 32px;
    height: 32px;
    border-radius: 100%;
    position: relative;
    background: #F8F8F8;
    top: 22px;
    left: 25px;
    z-index: 100;
    opacity: 0.9;
  transform: translate(-50%, -50%);
    pointer-events: none;
}

.pb-20{
  padding-bottom: 30px;
}


.circle:before,
.circle:after{
    content:'';position:absolute;top:0;left:0;right:0;bottom:0;
    pointer-events: none;
}

.circle.plus:before,
.circle.plus:after {
    background:  #FFDD0F;
    pointer-events: none;
}
.circle.plus:before{
    width: 3px;
    margin: 3px auto;
    pointer-events: none;
}
.circle.plus:after{
    margin: auto 3px;
    height: 3px;
    pointer-events: none;

}

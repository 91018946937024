.modal {
  z-index: 100;
  position: absolute;
  top: 10vh;
  
  left: calc(25%);
  width: 50%;
  background: white;

}



.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #ffffff;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.button-close
{
    position: absolute;
    color: white;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.margin-t-0{
    padding-top:0px !important;
    margin-top:0px !important;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}


@media only screen and (max-width: 1400px) {

  .modal {
    z-index: 100;
    position: absolute;
    top: 10vh;
    
    left: calc(15%);
    width: 60%;
    background: white;
  
  }
  
}



@media only screen and (max-width: 1024px) {

  .modal {
    z-index: 100;
    position: absolute;
    top: 10vh;
    
    left: calc(5%);
    width: 90%;
    background: white;
  
  }
  
}


@media only screen and (max-width: 414px) {
  .modal {
    z-index: 100;
    position: absolute;
    top: 10vh;
    left: calc(0%);
    width: 100%;
    background: white;
  
  }
}


.max-width-1050 {
  max-width: 1050px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-x-15 {
  padding: 0 15px;
}

.width-230 {
  width: 230px;
}

.reschedule-btn {
  padding-left: 0;
  padding-right: 0;
}

.ticker-container {
    width: 100%;
    height: 28px;
    background-color: #ffe256;
    overflow: hidden;
    box-sizing: content-box;
}

section {
    width: 100%;
    height: 28px;
    background-color: #ffe256;
    overflow: hidden;

}

section.scroll{
    width: 100%;
    display: flex;
}

section.scroll div
{
    color: #000 ;
    font-size: 21px;
    line-height: 28.9px;
    font-weight: bold;
    font-family: franklin;
    text-transform: uppercase;
    white-space: nowrap;
    padding-right: 10px;
}



section.scroll div span
{
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: black;
    color: #ffe256;
    padding-left: 5px;
    padding-right: 5px;
    text-shadow:
        0.3px 0.3px 0 #000,
      -0.3px -0.3px 0 #000,  
      0.3px -0.3px 0 #000,
       -0.3px 0.3px 0 #000,
       0.3px 0.3px 0 #000;
}

@keyframes animate
{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}

@keyframes animate2
{
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-200%);
    }
}
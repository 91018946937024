@font-face {
    font-family: avalon-regular;
    src: url(./assets/font/Avalon_Regular.ttf);
}

@font-face {
    font-family: avalon-bold;
    src: url(./assets/font/Avalon_Bold.ttf);
}

@font-face {
    font-family: avalon-plain;
    src: url(./assets/font/Avalon_Plain.ttf);
}

@font-face {
  font-family: din;
  src: url(./assets/font/DIN2014-Light.ttf);
}

@font-face {
    font-family: franklin;
    src: url(./assets/font/FranklinGothicBT-ExtraCondensed.otf);
  }

body{
    font-family: avalon-regular;
    color: #212121;
}

div.padding-small{
  padding-top: 85px;
}

.p-t-72{
  padding-top:72px;
}


.p-t-46{
  padding-top:46px;
}


.padding-b-24{
  padding-bottom: 24px;
}


.padding-b-16{
  padding-bottom: 16px;
}


p.banner-subtitle{
    font-size: 18px;
    font-family: avalon-bold;
    color: #212121;
  line-height: 22px;
  letter-spacing: 0;
}

h2.reselfridges-title {
  font-size: 3.5rem;
  font-weight: 900;
  font-family: 'franklin';
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
}


h1.banner-title{
    font-size: 50px;
    font-family: avalon-bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: #212121;
}

h2.does-title{
    font-size: 32px;
    font-family: avalon-bold;
    color: #212121;
}

body.modal-hook {
  overflow: hidden;
}


p.contact{
  font-size: 18px;
  font-family: avalon-bold;
  color: #545454;
}


h2.banner-content-text{
    font-size: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    color: #212121;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}




h2.subtitle {
    font-family: avalon-bold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #212121;
  }


  
h2.whyUseResell {
  color: #212121;
  font-family: avalon-bold;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 41px;
}
  p.details{
    font-family: avalon-regular;
    font-size: 16px;
    line-height: 1.5;
    color: #212121;
  }

  .contactDetails{
    font-family: avalon-regular;
    font-size: 16px;
    line-height: 1.5;
    color: #212121;
  }


  p.attach{
    font-family: avalon-bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #212121;
  }

  p.doesDetails{
    font-family: avalon-regular;
    font-size: 14px;
    color: #212121;
  }




  p.titleDetails{
    font-family: avalon-bold;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    color: #212121;
  }

  .any-extras{
    font-family: avalon-regular;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #212121;
  }

  


    h2.title{
    font-size: 20px;
      font-family: avalon-bold;
      color: #212121;
  }


  
p.mute{
  font-family: din;
  font-size: 14px;
  line-height: 1.5;
  color: #212121;
  
}

.note{
  font-family: din;
  font-size: 14px;
  line-height: 0;
  letter-spacing: 0;
  color: #212121;
}

.noteReview{
  font-family: din;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0;
  color: #212121;
}



.background-primary{
    background-color: #ffe256;
}

.background-secondary{
  background-color:#F8F8F8;
  border: solid 1px #e7e7e7;
}


.text-align-center{
  text-align: center;
}

.center{
  margin-left: auto;
  margin-right: auto;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.upper-case {
  text-transform: uppercase;
}

.cta-h1-titles {
  font-family: 'avalon-bold';
  font-size: 28px;
  margin: 0;
}

.btt-w-240 {
  width: 240px;
}
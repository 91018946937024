
  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }
  
  .form-control label {
    font-family: avalon-plain;
    margin-bottom: 9px;
    letter-spacing: 0;
    color: #545454;
    font-size: 14px;
    line-height: 16px;
  }
  
  .form-control input,
  .form-control textarea {
    width: 100%;
    height: 44px;
    font-family: avalon-plain;
    font-size: 14px;
    border: 1px solid #545454;
    background: #ffffff;
    padding: 0.15rem 0.25rem;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 10px;
  }
  ::placeholder{
      color: #B8B8B8;
     
  }

  .form-control textarea{
    margin-bottom: 10px;
    font-size: 12px;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #B8B8B8;
    margin-top: 14px;
    margin-bottom: 14px;
    margin-left: 12px;
   }
  
  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: white;
    border: 2px solid #606060;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffffff;
  }

  .form-control textarea {
    height: 141px;
    border-color: #545454;
  }
.maintenance-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    margin: calc(20px + 100vh / 8) auto 40px;
}

.warning-image{
    width: 40px;
    height: 40px;
}

.maintenance-title{
    margin: 0;
    font-family: avalon-bold;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}

.maintenance-description{
    margin: 0;
    font-size: 16px;
    text-align: center;
}

.redirect-button{
    padding-top: 11px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-48 {
    margin-bottom: 48px;
}
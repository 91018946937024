.autocompleteContainer {
  position: relative;
}

.autocompleteList {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

.autocompleteItemEmpty {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  list-style: none;
}

.autocompleteItem {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
  list-style: none;
}

.autocompleteItem:hover {
  background-color: #e9e9e9;
}

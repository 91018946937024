.details__title {
  font-size: 1.7em;
  margin: 0;
  font-family: 'avalon-bold';
}

.details__container {
  border: 1px solid #f1f1f1;
  margin-top: 1rem;
}

.details__container__title {
  background-color: #f1f1f1;
  padding-left: 1rem;
  line-height: 2.2rem;
  font-weight: bolder;
}

.details__container__images {
  padding: 8px;
}

.details__container__text {
  padding-left: 1rem;
  line-height: 3rem;
  font-weight: bolder;
}




label.customCheckbox {
  box-sizing: border-box;
  text-align: center;
  padding-top: 12.51px;
  padding-bottom: 12.51px;
  padding-right: 35px;
  padding-left: 35px;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid #E7E7E7;
  background-color: #F8F8F8;
  color: black;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}



label.customCheckbox div{
    font-family: avalon-plain;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
}


label.customCheckbox::before {
   
    position: relative;
    visibility: hidden;
    cursor: pointer;
}


label.customCheckbox::after {
   
    position: relative;
    visibility: hidden;
    cursor: pointer;
}


input.customCheckbox:checked + label.customCheckbox div:after{
    content: " \2713";
}

input.customCheckbox {
    position: relative;
    visibility: hidden;
    cursor: pointer;
}


input.customCheckbox:checked + label.customCheckbox {
  border: 1px solid #545454;
  background-color: #F8F8F8;
}




.custom-control label,
.custom-control input,
.custom-control textarea {
  display: block;
}

.custom-control label {
  font-family: avalon-plain;
  letter-spacing: 0;
  color: #545454;
  font-size: 14px;
  line-height: 16px;
}

.custom-control input,
.custom-control textarea {

  font-family: avalon-plain;
  font-size: 14px;
  border: 1px solid #545454;
  background: #ffffff;
  padding: 0.15rem 0.25rem;
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-control input:focus,
.custom-control textarea:focus {
  outline: none;
  background: white;
  border: 2.5px solid #606060;
}

.custom-control--invalid label,
.custom-control--invalid p {
  color: red;
}

.custom-control--invalid input,
.custom-control--invalid textarea {
  border-color: red;
  background: #ffffff;
}

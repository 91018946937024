
  .form-control select {
    display: block;
  }
  
  .form-control label {
    font-family: avalon-plain;
    margin-bottom: 9px;
    letter-spacing: 0;
    color: #545454;
    font-size: 14px;
    line-height: 13px;
  }
  
  .form-control select{
    width: 100%;
    height: 44px;
    font-family: avalon-plain;
    font-size: 14px;
    border: 1px solid #545454;
    background: #ffffff;
    padding: 0.15rem 0.25rem;
    padding-top: 14px;
    padding-bottom: 12px;
    padding-left: 12px;
;
  }

  .selected-placeholder {
    color: #B8B8B8;
  }

  
  .form-control select:focus {
    outline: none;
    background: white;
    border: 2px solid #606060;
  
  }

  .form-control select[disabled] {
    cursor: not-allowed;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid select,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffffff;
  }

  b.note{
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  label.note{
    font-family: din;
  }

  .select-black{
    color: #212121;
  }
.button {
    min-width: 139px;
    height: 40px;
    font-family: avalon-bold;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    align-items: center;
    border: none;
    outline: none;
    display: inline-block;
    cursor:pointer;
  
    padding-left: 37px;
    padding-right: 37px;    
  }
  

  button:disabled {
    box-sizing: border-box;   
    cursor: not-allowed;
    border: 1px solid #E7E7E7;
    background-color: #F8F8F8;
  }
div.padding-small{
    padding-top: 64px;
}

.background-off-white{
    background-color: #F8F8F8;
}

.background-off-black{
    background-color: #545454;
    color: #f8f8f8;
    font-family: avalon-plain;
    font-weight: normal;
    font-size: 14px;
}


.background-off-white2{
    background-color: #E7E7E7;
    height: 100%;
}


.p-10{
    padding-top: 10px;
    padding-left: 13px;
    padding-right: 10px;
}

.p-t-98{
    padding-top: 98px;
}


.p-l-15{
    
    margin-left: 20px;
    
}


.p-b-5{
    padding-bottom: 5px;
}


.pl-10{
    padding-left: 10px;
}

.pb-20{
    padding-bottom: 20px;
}

.pb-30{
    padding-bottom: 30px;
}
.pt-20{
    padding-top: 20px;
}


.pr-30{
 padding-right: 30px;
}

.p-l-r-15{
    padding-left: 17px;
    padding-right: 17px;
}





hr.offwhite{
        border-top: 1px solid #E7E7E7;
}

.p-5-5{
    padding-bottom: 5px;
}

a p{
    color: black;
}

a{
    text-decoration: none; 
    color: black;
}

a:hover p{
    text-decoration: none; 
    color: #898989;
}

.p-b-20{
    padding-bottom:20px;
}

p.details.p-b-20{
    line-height: 20px;
}

.background-off-white.text-align-center.mobile-class-footer-details.pt-10{
    padding: 30px;
}



.dot {
    height: 5px;
    width: 5px;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    display: block;
  }


  div.row.p-10{
      max-width: 2000px;
  }


  .pt-10{
      padding-top: 10px;
  }

  div.text-align-center.mobile-class-footer-details{
     
      margin: 10px;
  }

  div.padding-tb85{
    display: none;
}

div.remove-when-big{
    display: none;
}
div.text-align-center.mobile-class-footer-details{
 
    margin: 0 !important;
  
}

a.pictureRemove{
    margin-top: 4px;
}


  @media only screen and (max-width: 414px) {

    span.dot.align-self-center{
        display: none;
    }

    div.mobile-class-footer-details{
        text-align: left !important;
    }

    div.text-align-center.mobile-class-footer-details{
        padding: 20px;
        margin: 0 !important;
      
    }

    div.background-off-white2.pt-10{
        padding: 0 !important;
    }
  
    div.container-fluid.mobile-resp-fluid{
        padding: 0!important
    }


    div.contactDetails b{
        font-weight: normal;
    }

    
    div.contactDetails.mobile-footer-paragraph{
        font-weight: bold;
    }

    p.details.p-l-r-15{
        padding-left:0;
        padding-right: 50px;
    }

    a.pictureRemove{
   
        display: none;
    }

    a.pictureBelow{
        display: block;
    }
    div.padding-tb85{
        display: block;
        padding-bottom: 85px;
        padding-top: 85px;
    }

    div.remove-when-big{
        display: block;
    }

   p.padding-right-mobile{
       padding-right: 200px !important;
   }
  
  }
  


.top-10-vh{
    top: 10vh;
}

  p.details{
    line-height: 1.5;
  }



  
.vh-100{
    height: 60vh;
}

.lh-40{
  line-height: 40px;
}

.p-b-65{
  padding-bottom: 65px;
}




.checkmarks{
  width: 30px;
  height: 30px;
  z-index: 100;
  position: relative;
  top: 5px;
  left: 5px;
 }
 
 .close-x {
   stroke: rgb(255, 255, 255);
   fill: transparent;
   stroke-linecap: round;
   stroke-width: 1;
   box-sizing: inherit;
 }
 
 
 
 .box-circle {
   width: 30px;
   height: 30px;
   z-index: 100;
   position: relative;
   top: 5px;
   left: 5px;
   background-color: #ED462F;
   border-radius: 50%;
 }
 
 
 .photosModal .image-object-fit{
   position: relative;
   top: -30px;
   left: 0px;
   height: 100%;
   width: 100%;
 }
 
 
 



@media only screen and (min-width: 430px) {
  .margin-121{
    margin-left:121px;
    margin-right:121px;
}
}




@media only screen and (max-width: 414px) {

}


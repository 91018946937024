.header {
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: space-between;

align-items: center; 
}

.EUR-English {
  font-family: avalon-regular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b7b88;
}



.EUR-English .text-style-2 {
  font-weight: bold;
  color: #7b7b88;
}
.text-flag {
  display:inline-block;
  vertical-align: middle;
}

.logo{
  text-align: center;
  flex: 1;
  
}

.left {
  display: inline-block;
  min-width: 20px;
  width: 15px;
  height: 21px;
  vertical-align: middle;
  padding-left:56px;
}


.right {
  display: inline-block;
  min-width: 20px;
  width: 170px;
  height: 21px;
}


@media only screen and (max-width: 430px) {
  .left {
    display: none;
  }
  .right {
    display: none;
  }
  div.text-flag{
    display: none;
  }

}




.fill {
  width: 177px;
  height: 27px;
  background-color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.hidden {
  display: hidden;
}

.drawerNav{
  height: 100%;
}
/* Base for label.normal-label styling */
input.normal-checkbox:not(:checked),
input.normal-checkbox:checked {
  position: absolute;
  left: -9999px;
}
input.normal-checkbox:not(:checked) + label.normal-label,
input.normal-checkbox:checked + label.normal-label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
     
}

/* checkbox aspect */
input.normal-checkbox:not(:checked) + label.normal-label:before,
input.normal-checkbox:checked + label.normal-label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 16px; 
  height: 16px;
  border: none;
  background: #fff;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  
}
/* checked mark aspect */
input.normal-checkbox:not(:checked) + label.normal-label:after,
input.normal-checkbox:checked + label.normal-label:after {
  content: '\2714';
  position: absolute;
  top: .15em; left: .22em;
  font-size: 14px;
  font-family: din;
  color: black;

}
/* checked mark aspect changes */
input.normal-checkbox:not(:checked) + label.normal-label:after {
  opacity: 0;
  transform: scale(0);
}
input.normal-checkbox:checked + label.normal-label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
input.normal-checkbox:not(:checked) + label.normal-label:before{
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
  width: 16px;
  height: 16px;
}

input.normal-checkbox:checked + label.normal-label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ffe256;
    width: 16px;
    height: 16px;
  }
  

/* accessibility */
input.normal-checkbox:checked:focus + label.normal-label:before,
input.normal-checkbox:not(:checked):focus + label.normal-label:before {
  border: none;
}

/* hover style just for information */
label.normal-label:hover:before {
  border: none;
}

label.normal-label > div{
    margin-left: 17px;
    line-height: 1.43;
    letter-spacing: normal;

}




.additional-photos__list {
  list-style: none;
  padding: 0;
}

.additional-photos__step2-container {
  border-top: 1px solid black;
  padding-top: 60px;
  margin: 0 15px;
}

.additional-photos__photo-container {
  position: relative;
  width: fit-content;
}

.additional-photos__photo-container__close {
  position: absolute;
  top: 4px;
  right: 19px;
  background-color: white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.additional-photos__photo {
  width: 100%;
  margin-bottom: 10px;
}

.max-width-1050 {
  max-width: 1050px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.padding-x-15 {
  padding: 0 15px;
}

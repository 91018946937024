.so-whats-next {
  color: #212121;
  font-family: avalon-bold;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
}

.padding-b-24 {
  padding-bottom: 24px;
}

.padding-b-14 {
  padding-bottom: 14px;
}

.padding-b-20 {
  padding-bottom: 20px;
}

.lh-40{
  line-height: 40px;
}


.padding-b-24 {
  padding-bottom: 24px;
}

.padding-b-30 {
  padding-bottom: 30px;
}

.padding-b-48 {
  padding-bottom: 38px;
}

.padding-t-32 {
  padding-top: 32px;
}

.pt-15 {
  padding-top: 15px;
}

.padding-t-48 {
  padding-top: 48px;
}

.padding-b-64 {
  padding-bottom: 64px;
}

.pb-50 {
  padding-bottom: 50px;
}

.padding-b-16 {
  padding-bottom: 16px;
}

.padding-t-402 {
  padding-top: 402px;
}

@media only screen and (max-width: 430px) {
  .margin-l-24 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .marginl-r24 {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}

.margin-l-24 {
  margin-left: 24px;
}

.marginl-r24 {
  margin-left: 24px;
  margin-right: 47px;
}

.max-width-684 {
  max-width: 684px;
}

.max-width-516 {
  max-width: 516px;
}

.loading {
  position: fixed;
  top: 50vh;
  left: 50vw;
}

.oval-primary {
  width: 30px;
  height: 30px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 16px;
  font-family: avalon-bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  border: none;
  color: white;
}
.oval-secondary {
  width: 30px;
  height: 30px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 16px;
  font-family: avalon-bold;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  border: 0.1px solid #e7e7e7;
  color: black;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.h-134 {
  height: 134px;
}

.padding-b-66 {
  padding-bottom: 66px;
}

.padding-b-95 {
  padding-bottom: 95px;
}

.width-100 {
  width: 100px;
}

.width-208 {
  width: 208px;
}

.width-187 {
  width: 187px;
}

.width-133 {
  width: 133px;
}

.width-137 {
  width: 137px;
}

.width-160 {
  width: 160px;
}

.width-184 {
  width: 184px;
}

.width-121 {
  width: 121px;
}

@media only screen and (min-width: 430px) {
  .nopadding {
    padding-right: 0 !important;
    margin-right: 0 !important;
  }

  .nopadding-mid {
    padding: 0 !important;
    margin: 0 !important;
  }

  .nopadding-end {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }

  .nopadding-center {
    padding: 0 !important;
  }
}

.padding-t-8 {
  padding-top: 8px;
}

.yellowSquare {
  width: 3px;
  height: 18px;
  border: solid 1px #ffe256;
  background-color: #ffe256;
}

hr.grey {
  margin-top: 30px;
  border-top: 1px solid #e7e7e7;
}

.squarePicture {
  width: 103px;
  height: 103px;
  object-fit: contain;
  margin-right: 7.5px;
  margin-top: 7.5px;
}

.padding-b-3 {
  padding-bottom: 3px;
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.vh-100 {
  padding-top: 40vh;
  padding-bottom: 40vh;
}

@media only screen and (max-width: 414px) {
  h1.so-whats-next{
    font-size: 21px;
  }


  p.remove-when-mobile{
    display: none;
  }

  div.padding-add{
    margin-left: 26px;
    margin-right: 26px;
  }


  .margin-24-when-mb{
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 50px;
  }
  

  button.background-primary{
    width: 216px;
  }

  div.col-sm-4.padding-b-10.mobile-spec{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
      word-break: break-all;
  }




  div.col-sm-4.padding-b-10.mobile-6{
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
      word-break: break-all;
  }


  div.removeWhenMobile{
    display: none;
  }
  button.secondPanel{
    width: 212px;
  }
  .center-flex {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }


  .width-100 {
    width: 130px;
  }
  
  .width-208 {
    width: 220px;
  }
  
  .width-187 {
    width: 207px;
  }
  
  .width-133 {
    width: 170px;
  }
  
  .width-137 {
    width: 147px;
  }
  
  .width-160 {
    width: 200px;
  }
  
  .width-184 {
    width: 200px;
  }
  
  .width-121 {
    width: 170px;
  }


}



@media only screen and (max-width: 375px) {
  .width-100 {
    width: 110px;
  }
  
  .width-208 {
    width: 208px;
  }
  
  .width-187 {
    width: 187px;
  }
  
  .width-133 {
    width: 133px;
  }
  
  .width-137 {
    width: 137px;
  }
  
  .width-160 {
    width: 200px;
  }
  
  .width-184 {
    width: 190px;
  }
  
  .width-121 {
    width: 140px;
  }

}

textarea {
  resize: none;
}